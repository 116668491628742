import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { modifyWorkOrder } from '../../../module/workOrder/api';
import { success as showSuccessMessage } from '../../../module/message';
import handleApiResponse from '../../../utils/api/handleApiResponse';
import AuthenticateContext from '../../../provider/context/authenticate.context';
import Section from '../../../components/section/Section';
import SectionBody from '../../../components/section/SectionBody';
import { Grid, Column } from '../../../components/grid/Grid';
import FormItem from '../../../components/form/FormItem';
import Label from '../../../components/form/Label';
import Input from '../../../components/form/Input';
import Textarea from '../../../components/form/Textarea';
import Button from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';

const InstallationInformation = ({
  previousData,
  setPreviousData,
  data,
  setData,
  basicInformation,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { userInformation } = useContext(AuthenticateContext);
  const [modifyMode, setModifyMode] = useState(false);

  const handleChangeValue = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const handleCancelModify = () => {
    setData(previousData);
    setModifyMode(false);
  };

  const handleModify = () => {
    const [principalType, principal] = basicInformation.principal.split('_');
    handleApiResponse(
      modifyWorkOrder({
        wo_id: id,
        real_time: basicInformation.real_time,
        principal_type: principalType,
        principal: principal,
        wo_status: basicInformation.wo_status,
        memo: basicInformation.memo || '',
        actual_address: data.actual_address,
      }),
      () => {
        showSuccessMessage({ message: t('success:ModifyWorkOrder') });
        setPreviousData(data);
        setModifyMode(false);
      }
    );
  };

  return (
    <>
      <Section backgroundReverse noPaddingBottom>
        <SectionBody noPadding>
          <Grid columns={2}>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='terminalSerialNumber'>
                  {t('TerminalSerialNumber')}
                </Label>
                <Input
                  type='text'
                  id='terminalSerialNumber'
                  value={data.terminal_id}
                  disabled
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='hardwareSerialNumber'>
                  {t('HardwareSerialNumber')}
                </Label>
                <Input
                  type='text'
                  id='hardwareSerialNumber'
                  value={data.hwsn}
                  disabled
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='model'>{t('Model')}</Label>
                <Input type='text' id='model' value={data.model} disabled />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='header'>{t('Header')}</Label>
                <Input type='text' id='header' value={data.header} disabled />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='contactPerson'>{t('ContactPerson')}</Label>
                <Input
                  type='text'
                  id='contactPerson'
                  value={data.contact_person}
                  disabled
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='phone'>{t('Phone')}</Label>
                <Input type='text' id='phone' value={data.phone} disabled />
              </FormItem>
            </Column>
            <Column desktop={2}>
              <FormItem>
                <Label htmlFor='address'>{t('Address')}</Label>
                <Input type='text' id='address' value={data.address} disabled />
              </FormItem>
            </Column>
            <Column desktop={2}>
              <FormItem>
                <Label htmlFor='actualInstallationAddress'>
                  {t('ActualInstallationAddress')}
                </Label>
                <Input
                  type='text'
                  id='actualInstallationAddress'
                  value={data.actual_address}
                  disabled={!modifyMode}
                  onChange={(event) =>
                    handleChangeValue('actual_address', event.target.value)
                  }
                />
              </FormItem>
            </Column>
            <Column desktop={2}>
              <FormItem>
                <Label htmlFor='memo'>{t('Memo')}</Label>
                <Textarea id='memo' rows='4' value={data.memo} disabled />
              </FormItem>
            </Column>
          </Grid>
        </SectionBody>
      </Section>

      {[1, 2].includes(parseInt(userInformation.r_id, 10)) && (
        <ButtonGroup alignRight>
          {previousData.wo_status !== 7 && previousData.wo_status !== 8 && (
            <>
              {modifyMode ? (
                <>
                  <Button danger onClick={handleCancelModify}>
                    {t('button:Cancel')}
                  </Button>
                  <Button success onClick={handleModify}>
                    {t('button:Save')}
                  </Button>
                </>
              ) : (
                <Button
                  warning
                  onClick={() => {
                    setModifyMode(true);
                  }}
                >
                  {t('button:Modify')}
                </Button>
              )}
            </>
          )}
        </ButtonGroup>
      )}
    </>
  );
};

export default InstallationInformation;
