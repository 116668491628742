import axios from 'axios';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  MdMoreHoriz,
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdClose,
} from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import { FiFileText } from 'react-icons/fi';
import { IoMdOptions } from 'react-icons/io';
import {
  getWorkOrderListByDemandOrder,
  getWorkOrderInformation,
  previewWorkOrder,
  importWorkOrder,
  getUrgencyList,
} from '../../../module/workOrder/api';
import { success, error } from '../../../module/message';
import handleApiResponse from '../../../utils/api/handleApiResponse';
import formatDate from '../../../utils/format/formatDate';
import useToggleOptions from '../../../utils/hooks/useToggleOptions';
import Section from '../../../components/section/Section';
import SectionHeader from '../../../components/section/SectionHeader';
import SectionBody from '../../../components/section/SectionBody';
import SectionToolbar from '../../../components/section/SectionToolbar';
import Heading2 from '../../../components/heading/Heading2';
import Table from '../../../components/table/Table';
import { useLimitChange, usePageChange } from '../../../components/table/hooks';
import Dropdown, {
  DropdownItem,
  DropdownDivOption,
  DropdownButtonOption,
} from '../../../components/Dropdown';
import IconButton from '../../../components/button/IconButton';
import TitleModal from '../../../components/modal/TitleModal';
import Tab from '../../../components/Tab';
import Button from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import { Grid, Column } from '../../../components/grid/Grid';
import FormItem from '../../../components/form/FormItem';
import Label from '../../../components/form/Label';
import Input from '../../../components/form/Input';
import Tooltip from '../../../components/tooltip/Tooltip';
import Collapse from '../../../components/animate/Collapse';
import Block from '../../../components/parameter/Block';
import BadgeFill from '../../../components/badge/BadgeFill';
import FileDropzone from '../../../components/dropzone/FileDropzone';
import DatetimePickerV2 from '../../../components/datetimePicker/DatetimePickerV2';
import Select from '../../../components/select/Select';
import Checkbox from '../../../components/form/Checkbox';

const WorkOrder = ({ owner }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [workOrderList, setWorkOrderList] = useState([]);
  const [currentListData, setCurrentListData] = useState([]);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
  });
  const [tableOptions, toggleTableOptions] = useToggleOptions([
    { text: 'OrderNumber', checked: 1 },
    { text: 'Type', checked: 1 },
    { text: 'TerminalSerialNumber', checked: 1 },
    { text: 'HardwareSerialNumber', checked: 1 },
    { text: 'MerchantNumber', checked: 1 },
    { text: 'Customer', checked: 1 },
    { text: 'WorkOrderImportTime', checked: 1 },
    { text: 'ExpectFinishDate', checked: 1 },
    { text: 'Urgency', checked: 1 },
    { text: 'Status', checked: 1 },
    { text: 'Assignee', checked: 1 },
  ]);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);

  const handleGetWorkOrderListByDemandOrder = useCallback(() => {
    handleApiResponse(
      getWorkOrderListByDemandOrder({ cs_id: id }),
      (response) => {
        const { data } = response.data;
        setWorkOrderList(data || []);
      }
    );
  }, [id]);

  const showDetails = useCallback((wo_id) => {
    handleApiResponse(getWorkOrderInformation({ wo_id: wo_id }), (response) => {
      const { data } = response.data;
      TitleModal({
        size: 'normal',
        children: <WorkOrderDetail data={data} />,
      });
    });
  }, []);

  const generateActionDropdown = useCallback(
    ({ number, wo_id }) => {
      return (
        <Dropdown key={number}>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem onClick={() => showDetails(wo_id)}>
              <DropdownButtonOption>
                <FiFileText />
                {t('Details')}
              </DropdownButtonOption>
            </DropdownItem>
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [t, showDetails]
  );

  const tableColumns = useMemo(() => {
    const columns = tableOptions
      .filter((option) => {
        return option.checked === 1;
      })
      .map((option) => {
        switch (option.text) {
          case 'OrderNumber':
            return {
              title: t('OrderNumber'),
              fieldName: 'number',
              sortable: false,
            };
          case 'Type':
            return {
              title: t('Type'),
              fieldName: 'type_val',
              sortable: false,
            };
          case 'TerminalSerialNumber':
            return {
              title: t('TerminalSerialNumber'),
              fieldName: 'TID',
              sortable: false,
            };
          case 'HardwareSerialNumber':
            return {
              title: t('HardwareSerialNumber'),
              fieldName: 'hwsn',
              sortable: false,
              render(data) {
                if (!data) {
                  return '';
                }

                return data;
              },
            };
          case 'MerchantNumber':
            return {
              title: t('MerchantNumber'),
              fieldName: 'MID',
              sortable: false,
            };
          case 'Customer':
            return {
              title: t('Customer'),
              fieldName: 'merchant',
              sortable: false,
            };
          case 'WorkOrderImportTime':
            return {
              title: t('WorkOrderImportTime'),
              fieldName: 'import_time',
              sortable: false,
            };
          case 'ExpectFinishDate':
            return {
              title: t('ExpectFinishDate'),
              fieldName: 'real_time',
              sortable: false,
            };
          case 'Urgency':
            return {
              title: t('Urgency'),
              fieldName: 'urgency',
              sortable: false,
            };
          case 'Status':
            return {
              title: t('Status'),
              fieldName: 'lifephase',
              sortable: false,
            };
          case 'Assignee':
            return {
              title: t('Assignee'),
              fieldName: 'first_name',
              sortable: false,
              render(data, full) {
                return `${full.first_name} ${full.last_name}`;
              },
            };
          default:
            return null;
        }
      });

    columns.push({
      title: t('Action'),
      custom: true,
      width: '10%',
      align: 'center',
      render(data) {
        return generateActionDropdown(data);
      },
    });

    return columns;
  }, [tableOptions, generateActionDropdown, t]);

  const showImportModal = useCallback(() => {
    TitleModal({
      size: 'large',
      children: (
        <ImportModal
          id={id}
          owner={owner}
          handleGetWorkOrderListByDemandOrder={
            handleGetWorkOrderListByDemandOrder
          }
        />
      ),
    });
  }, [id, owner, handleGetWorkOrderListByDemandOrder]);

  useEffect(() => {
    handleGetWorkOrderListByDemandOrder();
  }, [handleGetWorkOrderListByDemandOrder]);

  useEffect(() => {
    const start = (listParams.page - 1) * listParams.limit;
    const end = listParams.page * listParams.limit;
    const tmpListData = workOrderList.slice(start, end);

    setCurrentListData(tmpListData);
  }, [workOrderList, listParams.page, listParams.limit]);

  return (
    <Section backgroundReverse>
      <SectionHeader>
        <SectionToolbar>
          <Button onClick={showImportModal}>{t('button:Import')}</Button>

          <Dropdown>
            <Dropdown.Toggle>
              <Button reverse>
                <IoMdOptions />
                {t('Columns')}
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Content>
              {tableOptions.map((option, index) => (
                <DropdownDivOption
                  key={index}
                  onClick={() => toggleTableOptions(index)}
                >
                  <Checkbox
                    onChange={() => toggleTableOptions(index)}
                    htmlFor={option.text}
                    noMargin
                    checked={option.checked}
                    text={t(`${option.text}`)}
                  />
                </DropdownDivOption>
              ))}
            </Dropdown.Content>
          </Dropdown>
        </SectionToolbar>
      </SectionHeader>
      <SectionBody noPadding>
        <Table
          backgroundReverse
          columns={tableColumns}
          data={currentListData}
          currentPage={listParams.page}
          limit={listParams.limit}
          onLimitChange={onLimitChange}
          total={workOrderList.length}
          onPageChange={onPageChange}
          translation={{
            info: t('table.info'),
            empty: t('table.empty'),
          }}
        />
      </SectionBody>
    </Section>
  );
};

const WorkOrderDetail = ({ data, close }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('customerInformation');

  const customerInformation = useMemo(() => {
    return {
      merchant_id: data.workorder.workorder[0]['MerchantID'],
      name: data.merchant,
      contact_person: data.workorder.workorder[0]['Contact Person'],
      phone: data.workorder.workorder[0]['Phone'],
      address: data.workorder.workorder[0]['Address'],
    };
  }, [data]);

  const installationInformation = useMemo(() => {
    return {
      number: data.workorder.workorder[0]['Number'],
      type: data.workorder.workorder[0]['type'],
      terminal_id: data.workorder.terminal[0]['TerminalID'],
      model: data.workorder.terminal[0]['Model'],
      expect_install_date: data.workorder.workorder[0]['Expect Instal. Date'],
      header: data.workorder.workorder[0]['Header 1'],
      contact_person: data.workorder.workorder[0]['Contact Person'],
      phone: data.workorder.workorder[0]['Phone'],
      address: data.workorder.workorder[0]['Address'],
      memo: data.workorder.workorder[0]['Memo'],
    };
  }, [data]);

  const parameterInformation = useMemo(() => {
    const {
      'FunctionRequest-Normal': functionRequestNormal,
      'FunctionRequest-MailOrder': functionRequestMailOrder,
      'FunctionRequest-DCC': functionRequestDCC,
      'FunctionRequest-ChoiceCard': functionRequestChoiceCard,
      'FunctionRequest-AMEX': functionRequestAMEX,
      ...rest
    } = data.workorder.parameter;

    return [
      { label: 'General', data: rest },
      {
        label: 'Function Request - Normal',
        data: functionRequestNormal,
      },
      {
        label: 'Function Request - MailOrder',
        data: functionRequestMailOrder,
      },
      {
        label: 'Function Request - DCC',
        data: functionRequestDCC,
      },
      {
        label: 'Function Request - Choice Card',
        data: functionRequestChoiceCard,
      },
      {
        label: 'Function Request - AMEX',
        data: functionRequestAMEX,
      },
    ];
  }, [data]);

  return (
    <>
      <TitleModal.Header handleClose={close}>
        {t('WorkOrderDetails')}
      </TitleModal.Header>
      <TitleModal.Body>
        <Tab selected={selectedTab} onChange={setSelectedTab}>
          <Tab.List>
            <Tab.Tab name='customerInformation'>
              {t('CustomerInformation')}
            </Tab.Tab>
            <Tab.Tab name='installationInformation'>
              {t('InstallationInformation')}
            </Tab.Tab>
            <Tab.Tab name='parameter'>{t('Parameter')}</Tab.Tab>
          </Tab.List>

          <Tab.Panel name='customerInformation'>
            <CustomerInformation data={customerInformation} />
          </Tab.Panel>

          <Tab.Panel name='installationInformation'>
            <InstallationInformation data={installationInformation} />
          </Tab.Panel>

          <Tab.Panel name='parameter'>
            <Parameter data={parameterInformation} />
          </Tab.Panel>
        </Tab>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Close')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

const ImportModal = ({
  id,
  owner,
  handleGetWorkOrderListByDemandOrder,
  close,
}) => {
  const { t } = useTranslation();
  const [urgencyList, setUrgencyList] = useState([]);
  const [workOrderData, setWorkOrderData] = useState({
    real_time: formatDate(Date.now()),
    ur_id: 1,
  });
  const [workOrder, setWorkOrder] = useState(null);
  const [cancelToken, setCancelToken] = useState(null);
  const [dropzoneState, setDropzoneState] = useState({
    isUploading: false,
    isRemoving: false,
  });
  const [currentListData, setCurrentListData] = useState([]);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
  });

  const workOrderList = useMemo(() => {
    if (!workOrder) return [];

    return workOrder.map((workOrder) => {
      return {
        number: workOrder.workorder['Number'],
        type: workOrder.workorder['type'],
        customer: workOrder.merchant['name_ch'],
        installDate: workOrder.workorder['Expect Instal. Date'],
        details: workOrder,
      };
    });
  }, [workOrder]);

  const handleSubmit = () => {
    if (!workOrder) {
      return;
    }

    handleApiResponse(
      importWorkOrder({
        cs_id: id,
        preview: workOrder,
        ur_id: workOrderData.ur_id,
        real_time: workOrderData.real_time,
      }),
      () => {
        success({ message: t('success:ModifyDemandOrder') });
        handleGetWorkOrderListByDemandOrder();
        close();
      }
    );
  };

  const handleAddedFile = useCallback(
    (file) => {
      const format = ['.txt'];
      const reg = file.name.match(/\.\w+$/);
      if (!format.includes(reg[0].toLowerCase())) {
        error({
          message: t('error:ImportFileType', {
            fileType: 'txt',
          }),
        });

        setDropzoneState((previous) => {
          return { ...previous, isRemoving: true };
        });
        return;
      }

      setCancelToken(axios.CancelToken.source());
      setDropzoneState((previous) => {
        return { ...previous, isUploading: true };
      });
    },
    [t]
  );

  const handleRemovedFile = useCallback(() => {
    setWorkOrder(null);
    setDropzoneState((previous) => {
      return { ...previous, isRemoving: false };
    });
  }, []);

  const handleCancelFile = useCallback(() => {
    cancelToken.cancel();
    setDropzoneState((previous) => {
      return { ...previous, isUploading: false };
    });
  }, [cancelToken]);

  const handleUploading = useCallback(
    (chunk) => {
      return new Promise((resolve, reject) => {
        if (!owner) {
          error({ message: t('error:PleaseSelectABank') });
          setDropzoneState((previous) => {
            return { ...previous, isRemoving: true };
          });
          reject();
        } else {
          const reader = new FileReader();
          reader.onload = () => {
            previewWorkOrder({ owner_id: owner, file_data: reader.result })
              .then((response) => {
                if (response) {
                  const { data } = response.data;
                  const lastestDate = data.reduce((lastestDate, workOrder) => {
                    const currentDate =
                      workOrder.workorder['Expect Instal. Date'];
                    if (currentDate > lastestDate) {
                      return currentDate;
                    }

                    return lastestDate;
                  }, '');
                  setWorkOrderData((previous) => {
                    return {
                      ...previous,
                      real_time: formatDate(
                        `${lastestDate.substring(
                          0,
                          10
                        )} ${lastestDate.substring(10)}`
                      ),
                    };
                  });
                  setWorkOrder(data);
                  resolve();
                } else {
                  setDropzoneState((previous) => {
                    return { ...previous, isRemoving: true };
                  });
                  reject();
                }
              })
              .catch((e) => {
                if (!axios.isCancel(e)) {
                  reject();
                  error({ title: e.title, message: e.message });
                }
              });
          };

          reader.onerror = () => {
            reject();
          };

          reader.readAsText(chunk);
        }
      });
    },
    [t, owner]
  );

  const handleComplete = useCallback(() => {
    setDropzoneState((previous) => {
      return { ...previous, isUploading: false };
    });
  }, []);

  const handleError = useCallback(() => {
    setDropzoneState((previous) => {
      return { ...previous, isUploading: false };
    });
  }, []);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);

  const showDetails = useCallback((data) => {
    TitleModal({
      size: 'normal',
      children: (
        <WorkOrderDetail
          data={{
            merchant: data.merchant.name_ch,
            workorder: {
              workorder: [data.workorder],
              terminal: [data.terminal],
              parameter: data.parameter,
            },
          }}
        />
      ),
    });
  }, []);

  const generateActionDropdown = useCallback(
    ({ number, details }) => {
      return (
        <Dropdown key={number}>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem onClick={() => showDetails(details)}>
              <DropdownButtonOption>
                <FiFileText />
                {t('Details')}
              </DropdownButtonOption>
            </DropdownItem>
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [t, showDetails]
  );

  useEffect(() => {
    const start = (listParams.page - 1) * listParams.limit;
    const end = listParams.page * listParams.limit;
    const tmpListData = workOrderList.slice(start, end);

    setCurrentListData(tmpListData);
  }, [workOrderList, listParams.page, listParams.limit]);

  useEffect(() => {
    handleApiResponse(getUrgencyList(), (response) => {
      let { urgency } = response.data.data;
      urgency = urgency || [];

      const data = urgency.map((urgency) => {
        return { id: urgency.ur_id, text: urgency.urgency };
      });

      setUrgencyList(data);
    });
  }, [t]);

  return (
    <>
      <TitleModal.Header handleClose={close}>{t('Import')}</TitleModal.Header>
      <TitleModal.Body>
        <Grid columns={2}>
          <Column desktop={2}>
            <FormItem>
              <FileDropzone
                message={t('DropzoneWorkOrderMessage')}
                accept='.txt'
                translation={{
                  Cancel: t('Cancel'),
                  FileIcon: t('FileIcon'),
                  RemoveFile: t('RemoveFile'),
                  Retry: t('Retry'),
                }}
                chunking={false}
                state={dropzoneState}
                onAddedFile={handleAddedFile}
                onRemovedFile={handleRemovedFile}
                onCanceledFile={handleCancelFile}
                onUploading={handleUploading}
                onCompleted={handleComplete}
                onError={handleError}
              />
            </FormItem>
          </Column>

          {workOrder && (
            <>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='expectFinishDate'>
                    {t('ExpectFinishDate')}
                  </Label>
                  <DatetimePickerV2
                    selected={workOrderData.real_time}
                    onConfirm={(date) => {
                      setWorkOrderData((previous) => {
                        return { ...previous, real_time: date };
                      });
                    }}
                  />
                </FormItem>
              </Column>
              <Column desktop={1}>
                <FormItem>
                  <Label required>{t('Urgency')}</Label>
                  <Select
                    fullWidth
                    options={urgencyList}
                    selected={workOrderData.ur_id}
                    onSelect={(id) => {
                      setWorkOrderData((previous) => {
                        return { ...previous, ur_id: id };
                      });
                    }}
                  />
                </FormItem>
              </Column>
            </>
          )}
        </Grid>

        {workOrder && (
          <Section noMarginBottom>
            <SectionBody noPadding>
              <Table
                columns={[
                  {
                    title: t('WorkOrderNumber'),
                    fieldName: 'number',
                    sortable: false,
                  },
                  {
                    title: t('Type'),
                    fieldName: 'type',
                    sortable: false,
                  },
                  {
                    title: t('Customer'),
                    fieldName: 'customer',
                    sortable: false,
                  },
                  {
                    title: t('ExpectInstallDate'),
                    fieldName: 'installDate',
                    sortable: false,
                  },
                  {
                    title: t('Action'),
                    custom: true,
                    width: '10%',
                    align: 'center',
                    render(data) {
                      return generateActionDropdown(data);
                    },
                  },
                ]}
                data={currentListData}
                currentPage={listParams.page}
                limit={listParams.limit}
                onLimitChange={onLimitChange}
                total={workOrderList.length}
                onPageChange={onPageChange}
                translation={{
                  info: t('table.info'),
                  empty: t('table.empty'),
                }}
              />
            </SectionBody>
          </Section>
        )}
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Close')}
          </Button>
          <Button success onClick={handleSubmit}>
            {t('button:Submit')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

const CustomerInformation = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Section noPaddingTop noPaddingBottom noMarginBottom>
      <SectionBody>
        <Grid columns={2}>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='merchantId'>{t('MerchantId')}</Label>
              <Input
                type='text'
                id='merchantId'
                value={data.merchant_id}
                disabled
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='name'>{t('Name')}</Label>
              <Input type='text' id='name' value={data.name} disabled />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='contact'>{t('Contact')}</Label>
              <Input
                type='text'
                id='contact'
                value={data.contact_person}
                disabled
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='phone'>{t('Phone')}</Label>
              <Input type='text' id='phone' value={data.phone} disabled />
            </FormItem>
          </Column>
          <Column desktop={2}>
            <FormItem>
              <Label htmlFor='address'>{t('Address')}</Label>
              <Input type='text' id='address' value={data.address} disabled />
            </FormItem>
          </Column>
        </Grid>
      </SectionBody>
    </Section>
  );
};

const InstallationInformation = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Section noPaddingTop noPaddingBottom noMarginBottom>
      <SectionBody>
        <Grid columns={2}>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='workOrderNumber'>{t('WorkOrderNumber')}</Label>
              <Input
                type='text'
                id='workOrderNumber'
                value={data.number}
                disabled
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='type'>{t('Type')}</Label>
              <Input type='text' id='type' value={data.type} disabled />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='terminalID'>{t('TerminalID')}</Label>
              <Input
                type='text'
                id='terminalID'
                value={data.terminal_id}
                disabled
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='model'>{t('Model')}</Label>
              <Input type='text' id='model' value={data.model} disabled />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='expectInstallDate'>
                {t('ExpectInstallDate')}
              </Label>
              <Input
                type='text'
                id='expectInstallDate'
                value={data.expect_install_date}
                disabled
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='header'>{t('Header')}</Label>
              <Input type='text' id='header' value={data.header} disabled />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='contact'>{t('Contact')}</Label>
              <Input
                type='text'
                id='contact'
                value={data.contact_person}
                disabled
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='phone'>{t('Phone')}</Label>
              <Input type='text' id='phone' value={data.phone} disabled />
            </FormItem>
          </Column>
          <Column desktop={2}>
            <FormItem>
              <Label htmlFor='address'>{t('Address')}</Label>
              <Input type='text' id='address' value={data.address} disabled />
            </FormItem>
          </Column>
        </Grid>
      </SectionBody>
    </Section>
  );
};

const Parameter = ({ data }) => {
  return data.map((parameter) => {
    return (
      <ParameterSection
        key={parameter.label}
        label={parameter.label}
        data={parameter.data}
      />
    );
  });
};

const ParameterSection = ({ label, data }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <Section>
      <SectionHeader inline>
        <Heading2>{label}</Heading2>
        <SectionToolbar>
          {show ? (
            <Tooltip title={t('Hide')}>
              <Button reverse onClick={() => setShow(!show)} iconOnly>
                <MdKeyboardArrowDown />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t('Show')}>
              <Button reverse onClick={() => setShow(!show)} iconOnly>
                <MdKeyboardArrowRight />
              </Button>
            </Tooltip>
          )}
        </SectionToolbar>
      </SectionHeader>
      <Collapse in={show} duration={500}>
        <SectionBody>
          <Grid columns={4}>
            {label === 'General'
              ? Object.entries(data).map(([key, value]) => {
                  if (typeof value === 'object') return null;

                  return (
                    <Column key={key} desktop={1}>
                      <FormItem>
                        <Block>{`${key}: ${value}`}</Block>
                      </FormItem>
                    </Column>
                  );
                })
              : Object.entries(data).map(([key, value]) => {
                  if (typeof value === 'object') return null;

                  return (
                    <Column key={key} desktop={1}>
                      <FormItem>
                        <Block>
                          {value === 'O' ? (
                            <BadgeFill small success>
                              <FaCheck />
                            </BadgeFill>
                          ) : (
                            <BadgeFill small danger>
                              <MdClose />
                            </BadgeFill>
                          )}
                          {key}
                        </Block>
                      </FormItem>
                    </Column>
                  );
                })}
          </Grid>
        </SectionBody>
      </Collapse>
    </Section>
  );
};

export default WorkOrder;
