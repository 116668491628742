import axios from 'axios';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  MdMoreHoriz,
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdClose,
} from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import { FaCheck } from 'react-icons/fa';
import { getOwnerList } from '../../module/owner/api';
import {
  getTroubleshootingList,
  createDemandOrder,
  getDemandOrderTypeList,
} from '../../module/demandOrder/api';
import {
  previewWorkOrder,
  importWorkOrder,
  getUrgencyList,
} from '../../module/workOrder/api';
import { success, error } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import formatDate from '../../utils/format/formatDate';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionToolbar from '../../components/section/SectionToolbar';
import SectionBody from '../../components/section/SectionBody';
import Heading1 from '../../components/heading/Heading1';
import Heading2 from '../../components/heading/Heading2';
import { Grid, Column } from '../../components/grid/Grid';
import FormItem from '../../components/form/FormItem';
import Label from '../../components/form/Label';
import Input from '../../components/form/Input';
import Select from '../../components/select/Select';
import Textarea from '../../components/form/Textarea';
import Checkbox from '../../components/form/Checkbox';
import FileDropzone from '../../components/dropzone/FileDropzone';
import Table from '../../components/table/Table';
import { useLimitChange, usePageChange } from '../../components/table/hooks';
import Dropdown, {
  DropdownItem,
  DropdownButtonOption,
} from '../../components/Dropdown';
import IconButton from '../../components/button/IconButton';
import TitleModal from '../../components/modal/TitleModal';
import Tab from '../../components/Tab';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';
import Tooltip from '../../components/tooltip/Tooltip';
import Collapse from '../../components/animate/Collapse';
import Block from '../../components/parameter/Block';
import BadgeFill from '../../components/badge/BadgeFill';
import DatetimePickerV2 from '../../components/datetimePicker/DatetimePickerV2';

const Create = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [ownerList, setOwnerList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [troubleshootingList, setTroubleshootingList] = useState([]);
  const [data, setData] = useState({
    owner: '',
    type: '',
    error_type: '',
    sales_id: '',
    problem: '',
    process_result: '',
  });
  const [workOrderData, setWorkOrderData] = useState({
    real_time: formatDate(Date.now()),
    ur_id: 1,
    prm_autoset: false,
  });
  const [workOrder, setWorkOrder] = useState(null);
  const [cancelToken, setCancelToken] = useState(null);
  const [dropzoneState, setDropzoneState] = useState({
    isUploading: false,
    isRemoving: false,
  });
  const [currentListData, setCurrentListData] = useState([]);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
  });
  const [urgencyList, setUrgencyList] = useState([]);

  const pathData = useMemo(() => {
    return [{ name: t('DemandOrder') }, { name: t('Create') }];
  }, [t]);

  const workOrderList = useMemo(() => {
    if (!workOrder) return [];

    return workOrder.map((workOrder) => {
      return {
        number: workOrder.workorder['Number'],
        type: workOrder.workorder['type'],
        customer: workOrder.merchant['name_ch'],
        installDate: workOrder.workorder['Expect Instal. Date'],
        details: workOrder,
      };
    });
  }, [workOrder]);

  const handleChangeValue = (value, key) => {
    const newData = { ...data };
    newData[key] = value;

    setData(newData);
  };

  const handleCreate = () => {
    if (!data.owner) {
      error({ message: t('error:PleaseSelectABank') });
      return;
    }

    if (data.type !== 0 && !data.type) {
      error({ message: t('error:PleaseSelectAType') });
      return;
    }

    if (data.error_type !== 0 && !data.error_type) {
      error({ message: t('error:PleaseSelectASubType') });
      return;
    }

    handleApiResponse(
      createDemandOrder({
        owner: parseInt(data.owner, 10),
        type: parseInt(data.type, 10),
        error_type: data.error_type,
        sales_id: data.sales_id,
        problem: data.problem,
        process_result: data.process_result,
      }),
      (response) => {
        const { cs_id } = response.data.data;

        if (workOrder) {
          handleApiResponse(
            importWorkOrder({
              cs_id: cs_id,
              preview: workOrder,
              ur_id: workOrderData.ur_id,
              real_time: workOrderData.real_time,
              prm_autoset: workOrderData.prm_autoset,
            }),
            () => {
              success({ message: t('success:CreateDemandOrder') });
              history.push({ pathname: '/demand-order/list' });
            }
          );
        } else {
          success({ message: t('success:CreateDemandOrder') });
          history.push({ pathname: '/demand-order/list' });
        }
      }
    );
  };

  const handleAddedFile = useCallback(
    (file) => {
      const format = ['.txt'];
      const reg = file.name.match(/\.\w+$/);
      if (!format.includes(reg[0].toLowerCase())) {
        error({
          message: t('error:ImportFileType', {
            fileType: 'txt',
          }),
        });

        setDropzoneState((previous) => {
          return { ...previous, isRemoving: true };
        });
        return;
      }

      setCancelToken(axios.CancelToken.source());
      setDropzoneState((previous) => {
        return { ...previous, isUploading: true };
      });
    },
    [t]
  );

  const handleRemovedFile = useCallback(() => {
    setWorkOrder(null);
    setDropzoneState((previous) => {
      return { ...previous, isRemoving: false };
    });
  }, []);

  const handleCancelFile = useCallback(() => {
    cancelToken.cancel();
    setDropzoneState((previous) => {
      return { ...previous, isUploading: false };
    });
  }, [cancelToken]);

  const handleUploading = useCallback(
    (chunk) => {
      return new Promise((resolve, reject) => {
        if (!data.owner) {
          error({ message: t('error:PleaseSelectABank') });
          setDropzoneState((previous) => {
            return { ...previous, isRemoving: true };
          });
          reject();
        } else {
          const reader = new FileReader();
          reader.onload = () => {
            previewWorkOrder({ owner_id: data.owner, file_data: reader.result })
              .then((response) => {
                if (response) {
                  const { data } = response.data;
                  const lastestDate = data.reduce((lastestDate, workOrder) => {
                    const currentDate =
                      workOrder.workorder['Expect Instal. Date'];
                    if (currentDate > lastestDate) {
                      return currentDate;
                    }

                    return lastestDate;
                  }, '');
                  setWorkOrderData((previous) => {
                    return {
                      ...previous,
                      real_time: formatDate(
                        `${lastestDate.substring(
                          0,
                          10
                        )} ${lastestDate.substring(10)}`
                      ),
                    };
                  });
                  setWorkOrder(data);
                  resolve();
                } else {
                  setDropzoneState((previous) => {
                    return { ...previous, isRemoving: true };
                  });
                  reject();
                }
              })
              .catch((e) => {
                if (!axios.isCancel(e)) {
                  reject();
                  error({ title: e.title, message: e.message });
                }
              });
          };

          reader.onerror = () => {
            reject();
          };

          reader.readAsText(chunk);
        }
      });
    },
    [t, data.owner]
  );

  const handleComplete = useCallback(() => {
    setDropzoneState((previous) => {
      return { ...previous, isUploading: false };
    });
  }, []);

  const handleError = useCallback(() => {
    setDropzoneState((previous) => {
      return { ...previous, isUploading: false };
    });
  }, []);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);

  const showDetails = useCallback((data) => {
    TitleModal({
      size: 'normal',
      children: <WorkOrderDetail data={data} />,
    });
  }, []);

  const generateActionDropdown = useCallback(
    ({ number, details }) => {
      return (
        <Dropdown key={number}>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem onClick={() => showDetails(details)}>
              <DropdownButtonOption>
                <FiFileText />
                {t('Details')}
              </DropdownButtonOption>
            </DropdownItem>
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [t, showDetails]
  );

  useEffect(() => {
    const start = (listParams.page - 1) * listParams.limit;
    const end = listParams.page * listParams.limit;
    const tmpListData = workOrderList.slice(start, end);

    setCurrentListData(tmpListData);
  }, [workOrderList, listParams.page, listParams.limit]);

  useEffect(() => {
    if (!data.type) {
      setData((previous) => {
        return { ...previous, error_type: '' };
      });
      return;
    }

    if (parseInt(data.type, 10) === 1) {
      setData((previous) => {
        return { ...previous, error_type: 999 };
      });
    } else {
      setData((previous) => {
        return { ...previous, error_type: 1 };
      });
    }
  }, [data.type]);

  useEffect(() => {
    handleApiResponse(getOwnerList(), (response) => {
      const { owner } = response.data.data;
      const data = owner.map((owner) => {
        return { id: owner.owner_id, text: owner.owner };
      });

      setOwnerList(data);
    });

    handleApiResponse(getDemandOrderTypeList(), (response) => {
      const { cs_type } = response.data.data;
      const data = cs_type.map((type) => {
        return { id: type.type_id, text: type.type };
      });

      setTypeList(data);
    });

    handleApiResponse(getTroubleshootingList(), (response) => {
      const { troubleshooting } = response.data.data;
      const data = troubleshooting.map((trouble) => {
        return { id: trouble.trouble_id, text: trouble.trouble };
      });

      setTroubleshootingList(data);
    });

    handleApiResponse(getUrgencyList(), (response) => {
      let { urgency } = response.data.data;
      urgency = urgency || [];

      const data = urgency.map((urgency) => {
        return { id: urgency.ur_id, text: urgency.urgency };
      });

      setUrgencyList(data);
    });
  }, [t]);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader>
          <Heading1>{t('CreateDemandOrder')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionBody noPadding>
              <Grid columns={2}>
                <Column desktop={2}>
                  <FormItem>
                    <Label htmlFor='bank' required>
                      {t('Bank')}
                    </Label>
                    <Select
                      fullWidth
                      options={ownerList}
                      selected={data.owner}
                      onSelect={(owner) => handleChangeValue(owner, 'owner')}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='type' required>
                      {t('Type')}
                    </Label>
                    <Select
                      fullWidth
                      options={typeList}
                      selected={data.type}
                      onSelect={(type) => handleChangeValue(type, 'type')}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='subType' required>
                      {t('SubType')}
                    </Label>
                    <Select
                      fullWidth
                      options={troubleshootingList}
                      selected={data.error_type}
                      onSelect={(errroType) =>
                        handleChangeValue(errroType, 'error_type')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='salesID'>{t('SalesID')}</Label>
                    <Input
                      type='text'
                      id='salesID'
                      value={data.sales_id}
                      onChange={(event) => {
                        handleChangeValue(event.target.value, 'sales_id');
                      }}
                    />
                  </FormItem>
                </Column>
                <Column desktop={2}>
                  <FormItem>
                    <Label htmlFor='problem'>{t('Description')}</Label>
                    <Textarea
                      id='problem'
                      rows='4'
                      value={data.problem}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'problem')
                      }
                    />
                  </FormItem>
                </Column>

                <Column desktop={2}>
                  <FormItem>
                    <Label htmlFor='processResult'>{t('ProcessResult')}</Label>
                    <Textarea
                      id='processResult'
                      rows='4'
                      value={data.process_result}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'process_result')
                      }
                    />
                  </FormItem>
                </Column>
              </Grid>
            </SectionBody>
          </Section>

          <Section backgroundReverse>
            <SectionHeader inline>
              <Heading2>{t('WorkOrder')}</Heading2>
              <SectionToolbar>
                <Checkbox
                  htmlFor='automaticImportParameters'
                  text={t('AutomaticImportParameters')}
                  onChange={() => {
                    setWorkOrderData((previous) => {
                      return {
                        ...previous,
                        prm_autoset: workOrderData.prm_autoset ? false : true,
                      };
                    });
                  }}
                  checked={workOrderData.prm_autoset}
                />
              </SectionToolbar>
            </SectionHeader>
            <SectionBody>
              <Grid columns={2}>
                <Column desktop={2}>
                  <FormItem>
                    <FileDropzone
                      message={t('DropzoneWorkOrderMessage')}
                      accept='.txt'
                      translation={{
                        Cancel: t('Cancel'),
                        FileIcon: t('FileIcon'),
                        RemoveFile: t('RemoveFile'),
                        Retry: t('Retry'),
                      }}
                      chunking={false}
                      state={dropzoneState}
                      onAddedFile={handleAddedFile}
                      onRemovedFile={handleRemovedFile}
                      onCanceledFile={handleCancelFile}
                      onUploading={handleUploading}
                      onCompleted={handleComplete}
                      onError={handleError}
                    />
                  </FormItem>
                </Column>

                {workOrder && (
                  <>
                    <Column desktop={1}>
                      <FormItem>
                        <Label htmlFor='expectFinishDate'>
                          {t('ExpectFinishDate')}
                        </Label>
                        <DatetimePickerV2
                          selected={workOrderData.real_time}
                          onConfirm={(date) => {
                            setWorkOrderData((previous) => {
                              return { ...previous, real_time: date };
                            });
                          }}
                        />
                      </FormItem>
                    </Column>
                    <Column desktop={1}>
                      <FormItem>
                        <Label required>{t('Urgency')}</Label>
                        <Select
                          fullWidth
                          options={urgencyList}
                          selected={workOrderData.ur_id}
                          onSelect={(id) => {
                            setWorkOrderData((previous) => {
                              return { ...previous, ur_id: id };
                            });
                          }}
                        />
                      </FormItem>
                    </Column>
                  </>
                )}
              </Grid>

              {workOrder && (
                <Section noMarginBottom>
                  <SectionBody noPadding>
                    <Table
                      columns={[
                        {
                          title: t('WorkOrderNumber'),
                          fieldName: 'number',
                          sortable: false,
                        },
                        {
                          title: t('Type'),
                          fieldName: 'type',
                          sortable: false,
                        },
                        {
                          title: t('Customer'),
                          fieldName: 'customer',
                          sortable: false,
                        },
                        {
                          title: t('ExpectInstallDate'),
                          fieldName: 'installDate',
                          sortable: false,
                        },
                        {
                          title: t('Action'),
                          custom: true,
                          width: '10%',
                          align: 'center',
                          render(data) {
                            return generateActionDropdown(data);
                          },
                        },
                      ]}
                      data={currentListData}
                      currentPage={listParams.page}
                      limit={listParams.limit}
                      onLimitChange={onLimitChange}
                      total={workOrderList.length}
                      onPageChange={onPageChange}
                      translation={{
                        info: t('table.info'),
                        empty: t('table.empty'),
                      }}
                    />
                  </SectionBody>
                </Section>
              )}
            </SectionBody>
          </Section>

          <ButtonGroup alignRight>
            <Button success onClick={handleCreate}>
              {t('button:Create')}
            </Button>
          </ButtonGroup>
        </SectionBody>
      </Section>
    </>
  );
};

const WorkOrderDetail = ({ data, close }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('customerInformation');

  const parameterInformation = useMemo(() => {
    const {
      'FunctionRequest-Normal': functionRequestNormal,
      'FunctionRequest-MailOrder': functionRequestMailOrder,
      'FunctionRequest-DCC': functionRequestDCC,
      'FunctionRequest-ChoiceCard': functionRequestChoiceCard,
      'FunctionRequest-AMEX': functionRequestAMEX,
      ...rest
    } = data.parameter;

    return [
      { label: 'General', data: rest },
      {
        label: 'Function Request - Normal',
        data: functionRequestNormal,
      },
      {
        label: 'Function Request - MailOrder',
        data: functionRequestMailOrder,
      },
      {
        label: 'Function Request - DCC',
        data: functionRequestDCC,
      },
      {
        label: 'Function Request - Choice Card',
        data: functionRequestChoiceCard,
      },
      {
        label: 'Function Request - AMEX',
        data: functionRequestAMEX,
      },
    ];
  }, [data]);

  return (
    <>
      <TitleModal.Header handleClose={close}>
        {t('WorkOrderDetails')}
      </TitleModal.Header>
      <TitleModal.Body>
        <Tab selected={selectedTab} onChange={setSelectedTab}>
          <Tab.List>
            <Tab.Tab name='customerInformation'>
              {t('CustomerInformation')}
            </Tab.Tab>
            <Tab.Tab name='installationInformation'>
              {t('InstallationInformation')}
            </Tab.Tab>
            <Tab.Tab name='parameter'>{t('Parameter')}</Tab.Tab>
          </Tab.List>

          <Tab.Panel name='customerInformation'>
            <CustomerInformation
              data={{
                merchantID: data.workorder['MerchantID'].trim(),
                name: data.merchant['name_ch'].trim(),
                contact: data.merchant['contact'].trim(),
                phone: data.merchant['phone'].trim(),
                address: data.merchant['address'].trim(),
              }}
            />
          </Tab.Panel>

          <Tab.Panel name='installationInformation'>
            <InstallationInformation
              data={{
                number: data.workorder['Number'].trim(),
                type: data.workorder['type'].trim(),
                terminalID: data.terminal['TerminalID'].trim(),
                model: data.terminal['Model'].trim(),
                installDate: data.workorder['Expect Instal. Date'].trim(),
                header: data.workorder['Header 1'].trim(),
                contact: data.workorder['Contact Person'].trim(),
                phone: data.workorder['Phone'].trim(),
                address: data.workorder['Address'].trim(),
              }}
            />
          </Tab.Panel>

          <Tab.Panel name='parameter'>
            <Parameter data={parameterInformation} />
          </Tab.Panel>
        </Tab>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Close')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

const CustomerInformation = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Section noPaddingTop noPaddingBottom noMarginBottom>
      <SectionBody>
        <Grid columns={2}>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='merchantId'>{t('MerchantId')}</Label>
              <Input
                type='text'
                id='merchantId'
                value={data.merchantID}
                disabled
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='name'>{t('Name')}</Label>
              <Input type='text' id='name' value={data.name} disabled />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='contact'>{t('Contact')}</Label>
              <Input type='text' id='contact' value={data.contact} disabled />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='phone'>{t('Phone')}</Label>
              <Input type='text' id='phone' value={data.phone} disabled />
            </FormItem>
          </Column>
          <Column desktop={2}>
            <FormItem>
              <Label htmlFor='address'>{t('Address')}</Label>
              <Input type='text' id='address' value={data.address} disabled />
            </FormItem>
          </Column>
        </Grid>
      </SectionBody>
    </Section>
  );
};

const InstallationInformation = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Section noPaddingTop noPaddingBottom noMarginBottom>
      <SectionBody>
        <Grid columns={2}>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='workOrderNumber'>{t('WorkOrderNumber')}</Label>
              <Input
                type='text'
                id='workOrderNumber'
                value={data.number}
                disabled
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='type'>{t('Type')}</Label>
              <Input type='text' id='type' value={data.type} disabled />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='terminalID'>{t('TerminalID')}</Label>
              <Input
                type='text'
                id='terminalID'
                value={data.terminalID}
                disabled
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='model'>{t('Model')}</Label>
              <Input type='text' id='model' value={data.model} disabled />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='expectInstallDate'>
                {t('ExpectInstallDate')}
              </Label>
              <Input
                type='text'
                id='expectInstallDate'
                value={data.installDate}
                disabled
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='header'>{t('Header')}</Label>
              <Input type='text' id='header' value={data.header} disabled />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='contact'>{t('Contact')}</Label>
              <Input type='text' id='contact' value={data.contact} disabled />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='phone'>{t('Phone')}</Label>
              <Input type='text' id='phone' value={data.phone} disabled />
            </FormItem>
          </Column>
          <Column desktop={2}>
            <FormItem>
              <Label htmlFor='address'>{t('Address')}</Label>
              <Input type='text' id='address' value={data.address} disabled />
            </FormItem>
          </Column>
        </Grid>
      </SectionBody>
    </Section>
  );
};

const Parameter = ({ data }) => {
  return data.map((parameter) => {
    return (
      <ParameterSection
        key={parameter.label}
        label={parameter.label}
        data={parameter.data}
      />
    );
  });
};

const ParameterSection = ({ label, data }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <Section>
      <SectionHeader inline>
        <Heading2>{label}</Heading2>
        <SectionToolbar>
          {show ? (
            <Tooltip title={t('Hide')}>
              <Button reverse onClick={() => setShow(!show)} iconOnly>
                <MdKeyboardArrowDown />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t('Show')}>
              <Button reverse onClick={() => setShow(!show)} iconOnly>
                <MdKeyboardArrowRight />
              </Button>
            </Tooltip>
          )}
        </SectionToolbar>
      </SectionHeader>
      <Collapse in={show} duration={500}>
        <SectionBody>
          <Grid columns={4}>
            {label === 'General'
              ? Object.entries(data).map(([key, value]) => {
                  if (typeof value === 'object') return null;

                  return (
                    <Column key={key} desktop={1}>
                      <FormItem>
                        <Block>{`${key}: ${value}`}</Block>
                      </FormItem>
                    </Column>
                  );
                })
              : Object.entries(data).map(([key, value]) => {
                  if (typeof value === 'object') return null;

                  return (
                    <Column key={key} desktop={1}>
                      <FormItem>
                        <Block>
                          {value === 'O' ? (
                            <BadgeFill small success>
                              <FaCheck />
                            </BadgeFill>
                          ) : (
                            <BadgeFill small danger>
                              <MdClose />
                            </BadgeFill>
                          )}
                          {key}
                        </Block>
                      </FormItem>
                    </Column>
                  );
                })}
          </Grid>
        </SectionBody>
      </Collapse>
    </Section>
  );
};

export default Create;
