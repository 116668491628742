import MockAdapter from 'axios-mock-adapter';

const Mock = (axiosInstance) => {
  const mock = new MockAdapter(axiosInstance);

  mock.onPost('/login.php').reply(200, {
    status: 0,
    data: {
      user_id: 1,
      ticket: 'sssssss',
    },
    msg: 'success',
  });

  mock.onPost('/logout.php').reply(200, {
    status: 0,
    data: {},
    msg: 'success',
  });

  mock.onGet('/get_selfinfo.php').reply(200, {
    status: 0,
    data: {
      u_id: 1,
      r_id: 1,
      first_name: 'admin',
      last_name: 'testuser',
      email: 'test@gooooogle.com',
      phone: '09XXXXXXXX',
      inventory: 'http://example/inventory/template/index.html',
    },
    msg: 'Success',
  });

  mock.onPost('/send_pwdmail.php').reply(200, {
    status: 0,
    data: {},
    msg: 'success',
  });

  mock.onGet('/get_user.php').reply(200, {
    status: 0,
    data: {
      user: [
        {
          u_id: '1',
          first_name: 'ABC',
          last_name: 'XXX',
          email: 'aaa@bbb.ccc',
          phone: '289838XX',
        },
        {
          u_id: '2',
          first_name: 'ABC',
          last_name: 'XXX',
          email: 'aaa@bbb.ccc',
          phone: '289838XX',
        },
        {
          u_id: '3',
          first_name: 'ABC',
          last_name: 'XXX',
          email: 'aaa@bbb.ccc',
          phone: '289838XX',
        },
      ],
      current_page: 1,
      last_page: 1,
      limit: 10,
      total: 3,
    },
    msg: 'success',
  });

  mock.onGet('/get_user_simple.php').reply(200, {
    status: 0,
    data: [
      {
        u_id: '1',
        email: 'test@test.com',
        first_name: 'A',
        last_name: '客服',
      },
      {
        u_id: '2',
        email: 'test@test.com',
        first_name: 'B',
        last_name: '客服',
      },
      {
        u_id: '3',
        email: 'test@test.com',
        first_name: 'C',
        last_name: '客服',
      },
    ],
    msg: 'success',
  });

  mock.onGet('/get_userinfo.php').reply(200, {
    status: 0,
    data: {
      user_id: 1,
      r_id: 1,
      first_name: 'admin',
      last_name: 'testuser',
      email: 'test@gooooogle.com',
      phone: '09XXXXXXXX',
    },
    msg: 'Success',
  });

  mock.onPost('/del_user.php').reply(200, {
    status: 0,
    data: {},
    msg: 'success',
  });

  mock.onGet('/get_role.php').reply(200, {
    status: 0,
    data: {
      role: [
        {
          role: '管理員',
          role_id: '1',
        },
        {
          role: '客服',
          role_id: '2',
        },
        {
          role: '檢視者',
          role_id: '3',
        },
      ],
    },
    msg: 'success',
  });

  mock.onPost('/create_user.php').reply(200, {
    status: 0,
    data: {
      u_id: 1,
    },
    msg: 'success',
  });

  mock.onPost('/set_user.php').reply(200, {
    status: 0,
    data: {},
    msg: 'success',
  });

  mock.onGet('/get_engineer.php').reply(200, {
    status: 0,
    data: {
      engineer: [
        {
          en_id: 1,
          first_name: 'ABC',
          last_name: 'XXX',
          district: '北區',
          email: 'aaa@bbb.ccc',
          phone: '289838XX',
          memo: 'XX XXX',
        },
        {
          en_id: 2,
          first_name: 'ABC',
          last_name: 'XXX',
          district: '中區',
          email: 'aaa@bbb.ccc',
          phone: '289838XX',
          memo: 'XX XXX',
        },
        {
          en_id: 3,
          first_name: 'ABC',
          last_name: 'XXX',
          district: '南區',
          email: 'aaa@bbb.ccc',
          phone: '289838XX',
          memo: 'XX XXX',
        },
      ],
      current_page: 1,
      last_page: 1,
      limit: 10,
      total: 3,
    },
    msg: 'success',
  });

  mock.onGet('/get_engineer_simple.php').reply(200, {
    status: 0,
    data: [
      {
        en_id: 1,
        email: 'aaa@bbb.ccc',
        first_name: 'aaa',
        last_name: 'ccc',
        district: '北部',
      },
      {
        en_id: 2,
        email: 'aaa@bbb.ccc',
        first_name: 'aaa',
        last_name: 'ccc',
        district: '北部',
      },
      {
        en_id: 3,
        email: 'aaa@bbb.ccc',
        first_name: 'aaa',
        last_name: 'ccc',
        district: '北部',
      },
    ],
    msg: 'success',
  });

  mock.onPost('/del_engineer.php').reply(200, {
    status: 0,
    data: {},
    msg: 'success',
  });

  mock.onGet('/get_district.php').reply(200, {
    status: 0,
    data: {
      district: [
        {
          district: 'AAA',
          district_id: '1',
        },
      ],
    },
    msg: 'success',
  });

  mock.onPost('/create_engineer.php').reply(200, {
    status: 0,
    data: {},
    msg: 'success',
  });

  mock.onGet('/get_engineer_info.php').reply(200, {
    status: 0,
    data: {
      district_id: 1,
      first_name: 'AAA',
      last_name: 'BBB',
      phone: '09XXXXXXXX',
      email: 'aaa@bbb.com',
      memo: '地址',
    },
    msg: 'success',
  });

  mock.onGet('/get_merchant.php').reply(200, {
    status: 0,
    data: {
      merchant: [
        {
          m_id: 1,
          merchant_id: '15838506200',
          name: 'XX溫泉會館',
          name_en: 'XX HOTSPRING PESORE',
          contact: '韓XX',
          address: '台北市北投區XX路OO號',
          phone: '289838XX',
          memo: 'hhhhhhh',
        },
        {
          m_id: 2,
          merchant_id: '15838506200',
          name: 'XX溫泉會館',
          name_en: 'XX HOTSPRING PESORE',
          contact: '韓XX',
          address: '台北市北投區XX路OO號',
          phone: '289838XX',
          memo: 'hhhhhhh',
        },
        {
          m_id: 3,
          merchant_id: '15838506200',
          name: 'XX溫泉會館',
          name_en: 'XX HOTSPRING PESORE',
          contact: '韓XX',
          address: '台北市北投區XX路OO號',
          phone: '289838XX',
          memo: 'hhhhhhh',
        },
      ],
      current_page: 1,
      last_page: 1,
      limit: 10,
      total: 3,
    },
    msg: 'success',
  });

  mock.onGet('/get_merchant_simple.php').reply(200, {
    status: 0,
    data: [
      {
        m_id: 1,
        merchant_id: '15838506200',
        name: '水美溫泉會館',
      },
    ],
    msg: 'success',
  });

  mock.onGet('/get_merchant_info.php').reply(200, {
    status: 0,
    data: {
      merchant_id: '15838506200',
      name_ch: 'XX溫泉會館',
      name_en: 'XX HOTSPRING PESORE',
      contact: '韓XX',
      address: '台北市北投區XX路OO號',
      phone: '289838XX',
      memo: '',
    },
    msg: 'success',
  });

  mock.onPost('/set_merchant.php').reply(200, {
    status: 0,
    data: {},
    msg: 'success',
  });

  mock.onGet('/get_owner.php').reply(200, {
    status: 0,
    data: {
      owner: [
        {
          owner: 'AAA',
          owner_id: '1',
        },
        {
          owner: 'BBB',
          owner_id: '2',
        },
      ],
    },
    msg: 'success',
  });

  mock.onGet('/get_customer_service.php').reply(200, {
    status: 0,
    data: {
      customer_service: [
        {
          cs_id: 1,
          owner: 'XX銀行',
          first_name: 'A',
          last_name: '客服',
          type: 2,
          create_time: '2022-06-XX 16:00:00',
          email: 'aaa@bbb.com',
        },
        {
          cs_id: 1,
          owner: 'XX銀行',
          first_name: 'B',
          last_name: '客服',
          type: 2,
          create_time: '2022-06-XX 16:00:00',
          email: 'aaa@bbb.com',
        },
        {
          cs_id: 1,
          owner: 'XX銀行',
          first_name: 'B',
          last_name: '客服',
          type: 2,
          create_time: '2022-06-XX 16:00:00',
          email: 'aaa@bbb.com',
        },
      ],
      current_page: 1,
      last_page: 1,
      limit: 10,
      total: 3,
    },
    msg: 'success',
  });

  mock.onGet('/get_customer_service_type.php').reply(200, {
    status: 0,
    data: {
      cs_type: [
        {
          type: 'AAA',
          type_id: '1',
        },
        {
          type: 'BBB',
          type_id: '2',
        },
      ],
    },

    msg: 'success',
  });

  mock.onGet('/get_troubleshooting.php').reply(200, {
    status: 0,
    data: {
      troubleshooting: [
        {
          trouble: '01 下載KEY ',
          trouble_id: '1',
        },
        {
          trouble: '02 無螢幕或亂碼',
          trouble_id: '2',
        },
        {
          trouble: '03 機器浸水',
          trouble_id: '3',
        },
      ],
    },
    msg: 'success',
  });

  mock.onPost('/create_customer_service.php').reply(({ data }) => {
    console.log('Post: /create_customer_service.php', data);
    return [
      200,
      {
        status: 0,
        data: {
          cs_id: 3,
        },
        msg: 'success',
      },
    ];
  });

  mock.onPost('/preview_workorder.php').reply(200, {
    status: 0,
    data: [
      {
        merchant: {
          name_ch: '商店中文名稱',
          name_en: 'merchant name',
          contact: 'Janaka',
          address: '地址',
          phone: '02-XXXXXXXX',
        },
        workorder: {
          type: 'P',
          Number: 'P220121385062001',
          'Request Class': 'B',
          MerchantID: '15838506200',
          Time: '2022-01-22',
          'Expect Instal. Date': '2022-01-25 17:00',
          'MCC Code': '7011',
          'Contact Person': '韓R',
          Phone: '28964506',
          Address: '台北市北投區光明路224號',
          'Header 1': '水美溫泉會館-訂房部',
          'Header 2': 'SWEET ME HOTSPRING PESORE',
          'Header 3': '',
          GPN: 'F',
          Service: 'On_Site_Service',
          Memo: ':「 修改表頭一 」',
        },
        terminal: {
          TerminalID: '50852797',
          type: 'Y',
          Model: 'A910',
          TSN: '',
          'EDC Download Version': '520059',
          Handset: '1',
          Printer: '1',
          Logo: '0',
          '4DBC': 'N',
          ECR: 'N',
          'communication method': 'GPRS',
          Receipt_type: '2P',
          'IP Addr': '',
          'Host IP': '',
          Submask: '',
          Gateway: '',
          HostPort: '',
          AEPort: '',
          DinerPort: '',
        },
        parameter: {
          'FunctionRequest-Normal': {
            S: 'O',
            V: 'O',
            Sett: 'O',
            Refund: 'O',
            Pre: 'O',
            Tip: 'N',
            Adj: 'N',
            Referral: 'O',
            Offline: 'O',
            ManualPan: 'O',
            'Ref No': 'N',
            'Pre Com': 'N',
            'Pre Inc': 'N',
            'Pre Rev': 'N',
            IdVer: 'N',
            DRS_Flag: 'N',
          },
          AE_MID: '108104284',
          AE_TID: '77213261',
          AE_NewMid: '',
          'FunctionRequest-AMEX': {
            S: 'O',
            V: 'O',
            Sett: 'O',
            Refund: 'O',
            Pre: 'O',
            Tip: 'N',
            Adj: 'N',
            Referral: 'O',
            Offline: 'O',
            ManualPan: 'O',
            'Ref No': 'O',
            'Pre Com': 'N',
            'Pre Inc': 'N',
            'Pre Rev': 'N',
            IdVer: 'N',
            DRS_Flag: 'N',
          },
          'DFS Card': 'O',
          MO_MID: '',
          MO_TID: '',
          MO_NewMid: '',
          'FunctionRequest-MailOrder': {
            S: 'N',
            V: 'N',
            Sett: 'N',
            Refund: 'N',
            Pre: 'N',
            Tip: 'N',
            Adj: 'N',
            Referral: 'N',
            Offline: 'N',
            ManualPan: 'N',
            'Ref No': 'N',
            'No Swi-Pre': 'N',
            'Pre Com': 'N',
            'Pre Inc': 'N',
            'Pre Rev': 'N',
            IdVer: 'N',
            DRS_Flag: 'N',
          },
          Choics_MID: '',
          Choics_TID: '',
          Choics_NewMid: '',
          'FunctionRequest-ChoiceCard': {
            S: 'N',
            V: 'N',
            Sett: 'N',
            Refund: 'N',
            Pre: 'N',
            Tip: 'N',
            Adj: 'N',
            Referral: 'N',
            Offline: 'N',
            ManualPan: 'N',
            'Ref No': 'N',
            'No Swi-Pre': 'N',
            'Pre Com': 'N',
            'Pre Inc': 'N',
            'Pre Rev': 'N',
            IdVer: 'N',
            DRS_Flag: 'N',
          },
          DCC_MID: '15870414500',
          DCC_TerminalID: '50910406',
          DCC_MID2: '',
          'FunctionRequest-DCC': {
            S: 'N',
            V: 'N',
            Sett: 'N',
            Refund: 'N',
            Pre: 'N',
            Tip: 'N',
            Adj: 'N',
            Referral: 'N',
            Offline: 'N',
            ManualPan: 'N',
            'Ref No': 'N',
            'Pre Com': 'N',
            'Pre Inc': 'N',
            'Pre Rev': 'N',
            IdVer: 'N',
            DRS_Flag: 'N',
          },
          CUP_MID: '15880099600',
          CUP_TID: '50910406',
          CUP_MID2: '674015870110161',
          CUP_functions: {
            Pre: 'O',
            'Pre Com': 'O',
            'Pre Rev': 'O',
            'Pre Inc': 'O',
            Sale: 'O',
            'Sales Cancelled': 'O',
            Return: 'N',
            Manual: 'N',
          },
          FISC_MID: '',
          FISC_TID: '50852797',
          FISC_MID2: '',
          FISC_functions: {
            Con: 'O',
            Refund: 'O',
            Reversal: 'O',
            Checkout: 'O',
            'Cabinet number': 'O',
            IP: 'N',
            PORT: 'N',
          },
          'ESUN wallet': 'X',
          ESUN_MID: '',
          ESUNWallet_functions: {
            Alipay: 'X',
            'WeChat Pay': 'X',
            'LINE Pay': 'X',
            jkopay: 'X',
            GAMAPAY: 'X',
            OPAY: 'X',
          },
          'easy wallet': 'X',
          easy_MID: '',
          Memo: '',
        },
      },
    ],
    msg: 'success',
  });

  mock.onPost('/import_workorder.php').reply(200, {
    status: 0,
    data: {},
    msg: 'success',
  });

  mock.onPost('/get_customer_service_info.php').reply(200, {
    status: 0,
    data: {
      owner: 1,
      owner_name: '國泰世華商業銀行',
      u_id: 1,
      user_email: 'test1@test.com',
      type_id: 1,
      error_type: 2,
      problem: '問題說明',
      process_result: '處理結果說明',
      sales_id: '001',
    },
    msg: 'success',
  });

  mock.onPost('/set_customer_service.php').reply(({ data }) => {
    console.log('Post: /set_customer_service.php', data);
    return [
      200,
      {
        status: 0,
        data: {},
        msg: 'success',
      },
    ];
  });

  mock.onGet('/get_workorder.php').reply(({ params }) => {
    console.log('Get: /get_workorder.php', params);
    const { page, limit } = params;
    const list = [
      {
        wo_id: 1,
        number: '1000000001',
        MID: '1000000001',
        TID: '1000000001',
        type_val: '裝機',
        urgency: '普通件',
        real_time: '2022-06-27 15:00:00',
        import_time: '2022-06-26 15:00:00',
        header1: '1000000001',
        addres: '1000000001',
        phone: '09XXXXXXXX',
        contact: 'Customer Manager',
        merchant: '水美溫泉會館',
        hwsn: 'FFF123456789',
        lifephase: '未分配',
        first_name: 'janaka220809',
        last_name: 'janaka220809',
      },
      {
        wo_id: 2,
        number: '1000000002',
        MID: '1000000002',
        TID: '1000000002',
        type_val: '裝機',
        urgency: '普通件',
        real_time: '2022-06-27 15:00:00',
        import_time: '2022-06-26 15:00:00',
        header1: '1000000002',
        addres: '1000000002',
        phone: '09XXXXXXXX',
        contact: 'Customer Manager',
        merchant: '水美溫泉會館',
        hwsn: 'FFF123456790',
        lifephase: '未分配',
        first_name: 'janaka220809',
        last_name: 'janaka220809',
      },
      {
        wo_id: 3,
        number: '1000000003',
        MID: '1000000003',
        TID: '1000000003',
        type_val: '裝機',
        urgency: '普通件',
        real_time: '2022-06-27 15:00:00',
        import_time: '2022-06-26 15:00:00',
        header1: '1000000003',
        addres: '1000000003',
        phone: '09XXXXXXXX',
        contact: 'Customer Manager',
        merchant: '水美溫泉會館',
        hwsn: 'FFF123456791',
        lifephase: '未分配',
        first_name: 'janaka220809',
        last_name: 'janaka220809',
      },
    ];

    return [
      200,
      {
        status: 0,
        data: {
          workorder: list.slice((page - 1) * limit, page * limit),
          current_page: page,
          last_page: 1,
          limit: Math.ceil(list.length / limit),
          total: list.length,
        },
        msg: 'success',
      },
    ];
  });

  mock.onGet('/get_workorder_by_customerservice.php').reply(200, {
    status: 0,
    data: [
      {
        wo_id: 2,
        number: '111111111111',
        MID: '111111111111',
        TID: '111111111111',
        type: 1,
        type_val: 'N',
        urgency: '普通件',
        real_time: '2022-06-27 15:00:00',
        import_time: '2022-06-26 15:00:00',
        header1: '111111111111',
        addres: '111111111111',
        phone: '09XXXXXXXX',
        contact: 'Janaka Chang',
        merchant: 'merchant name',
        hwsn: 'FFF123456789',
        lifephase: '未分配',
        first_name: 'janaka220809',
        last_name: 'janaka220809',
      },
      {
        wo_id: 3,
        number: '111111111111',
        MID: '111111111111',
        TID: '111111111111',
        type: 1,
        type_val: 'N',
        urgency: '普通件',
        real_time: '2022-06-27 15:00:00',
        import_time: '2022-06-26 15:00:00',
        header1: '111111111111',
        addres: '111111111111',
        phone: '09XXXXXXXX',
        contact: 'Janaka Chang',
        merchant: 'merchant name',
        hwsn: 'FFF123456790',
        lifephase: '未分配',
        first_name: 'janaka220809',
        last_name: 'janaka220809',
      },
    ],
    msg: 'success',
  });

  mock.onGet('/get_workorder_log.php').reply(200, {
    status: 0,
    data: [
      {
        wol_id: '1',
        wo_id: '82',
        wol_principal_type: '1',
        wol_principal: '1',
        lifephase_id: '1',
        principal_type: '1',
        principal: '1',
        memo: '',
        real_time: '2022-10-05 16:25:19',
        time: '2022-10-03 16:25:19',
        lifephase: '未分配',
        username: 'janaka220809 janaka220809',
        principalname: 'janaka220809 janaka220809',
      },
      {
        wol_id: '4',
        wo_id: '82',
        wol_principal_type: '1',
        wol_principal: '1',
        lifephase_id: '3',
        principal_type: '1',
        principal: '16',
        memo: '2334',
        real_time: '2022-10-05 16:25:19',
        time: '2022-10-03 16:35:41',
        lifephase: '退回',
        username: 'janaka220809 janaka220809',
        principalname: '1234123456 aa123456',
      },
    ],
    msg: 'success',
  });

  mock.onGet('/get_workorder_info.php').reply((config) => {
    let lifephase_id = 2;
    if (config.params.wo_id === '334') {
      lifephase_id = 1;
    }

    return [
      200,
      {
        status: 0,
        data: {
          urgency: '普通件',
          Memo: 'Memo...',
          real_time: '2022-10-20 17:00:00',
          import_time: '2022-10-18 17:00:00',
          lifephase_id: lifephase_id,
          principal: 1,
          principal_type: 1,
          first_name: 'janaka220809',
          last_name: 'janaka220809',
          merchant: 'name',
          actual_address: '台北市北投區光明路224號之1',
          hwsn: 'FFF123456789',
          workorder: {
            workorder: [
              {
                type: 'P',
                Number: 'P220121385062001',
                'Request Class': 'B',
                MerchantID: '15838506200',
                Time: '2022-01-22',
                'Expect Instal. Date': '2022-01-25 17:00',
                'MCC Code': '7011',
                'Contact Person': '韓R',
                Phone: '28964506',
                Address: '台北市北投區光明路224號',
                'Header 1': '水美溫泉會館-訂房部',
                'Header 2': 'SWEET ME HOTSPRING PESORE',
                'Header 3': '',
                GPN: 'F',
                Service: 'On_Site_Service',
                Memo: ':「 修改表頭一 」',
              },
            ],
            terminal: [
              {
                TerminalID: '50852797',
                type: 'Y',
                Model: 'A910',
                TSN: '',
                'EDC Download Version': '520059',
                Handset: '1',
                Printer: '1',
                Logo: '0',
                '4DBC': 'N',
                ECR: 'N',
                'communication method': 'GPRS',
                Receipt_type: '2P',
                'IP Addr': '',
                'Host IP': '',
                Submask: '',
                Gateway: '',
                HostPort: '',
                AEPort: '',
                DinerPort: '',
              },
            ],
            parameter: {
              'FunctionRequest-Normal': {
                S: 'O',
                V: 'O',
                Sett: 'O',
                Refund: 'O',
                Pre: 'O',
                Tip: 'N',
                Adj: 'N',
                Referral: 'O',
                Offline: 'O',
                ManualPan: 'O',
                'Ref No': 'N',
                'Pre Com': 'N',
                'Pre Inc': 'N',
                'Pre Rev': 'N',
                IdVer: 'N',
                DRS_Flag: 'N',
              },
              AE_MID: '108104284',
              AE_TID: '77213261',
              AE_NewMid: '',
              'FunctionRequest-AMEX': {
                S: 'O',
                V: 'O',
                Sett: 'O',
                Refund: 'O',
                Pre: 'O',
                Tip: 'N',
                Adj: 'N',
                Referral: 'O',
                Offline: 'O',
                ManualPan: 'O',
                'Ref No': 'O',
                'Pre Com': 'N',
                'Pre Inc': 'N',
                'Pre Rev': 'N',
                IdVer: 'N',
                DRS_Flag: 'N',
              },
              'DFS Card': 'O',
              MO_MID: '',
              MO_TID: '',
              MO_NewMid: '',
              'FunctionRequest-MailOrder': {
                S: 'N',
                V: 'N',
                Sett: 'N',
                Refund: 'N',
                Pre: 'N',
                Tip: 'N',
                Adj: 'N',
                Referral: 'N',
                Offline: 'N',
                ManualPan: 'N',
                'Ref No': 'N',
                'No Swi-Pre': 'N',
                'Pre Com': 'N',
                'Pre Inc': 'N',
                'Pre Rev': 'N',
                IdVer: 'N',
                DRS_Flag: 'N',
              },
              Choics_MID: '',
              Choics_TID: '',
              Choics_NewMid: '',
              'FunctionRequest-ChoiceCard': {
                S: 'N',
                V: 'N',
                Sett: 'N',
                Refund: 'N',
                Pre: 'N',
                Tip: 'N',
                Adj: 'N',
                Referral: 'N',
                Offline: 'N',
                ManualPan: 'N',
                'Ref No': 'N',
                'No Swi-Pre': 'N',
                'Pre Com': 'N',
                'Pre Inc': 'N',
                'Pre Rev': 'N',
                IdVer: 'N',
                DRS_Flag: 'N',
              },
              DCC_MID: '15870414500',
              DCC_TerminalID: '50910406',
              DCC_MID2: '',
              'FunctionRequest-DCC': {
                S: 'N',
                V: 'N',
                Sett: 'N',
                Refund: 'N',
                Pre: 'N',
                Tip: 'N',
                Adj: 'N',
                Referral: 'N',
                Offline: 'N',
                ManualPan: 'N',
                'Ref No': 'N',
                'Pre Com': 'N',
                'Pre Inc': 'N',
                'Pre Rev': 'N',
                IdVer: 'N',
                DRS_Flag: 'N',
              },
              CUP_MID: '15880099600',
              CUP_TID: '50910406',
              CUP_MID2: '674015870110161',
              CUP_functions: {
                Pre: 'O',
                'Pre Com': 'O',
                'Pre Rev': 'O',
                'Pre Inc': 'O',
                Sale: 'O',
                'Sales Cancelled': 'O',
                Return: 'N',
                Manual: 'N',
              },
              FISC_MID: '',
              FISC_TID: '50852797',
              FISC_MID2: '',
              FISC_functions: {
                Con: 'O',
                Refund: 'O',
                Reversal: 'O',
                Checkout: 'O',
                'Cabinet number': 'O',
                IP: 'N',
                PORT: 'N',
              },
              'ESUN wallet': 'X',
              ESUN_MID: '',
              ESUNWallet_functions: {
                Alipay: 'X',
                'WeChat Pay': 'X',
                'LINE Pay': 'X',
                jkopay: 'X',
                GAMAPAY: 'X',
                OPAY: 'X',
              },
              'easy wallet': 'X',
              easy_MID: '',
              Memo: '',
            },
          },
        },
        msg: 'success',
      },
    ];
  });

  mock.onGet('/get_workorder_status.php').reply(200, {
    status: 0,
    data: {
      wo_status: [
        { lifephase_id: '1', lifephase: '\u672a\u5206\u914d' },
        { lifephase_id: '2', lifephase: '\u5df2\u5206\u914d' },
        { lifephase_id: '3', lifephase: '\u9000\u56de' },
        { lifephase_id: '4', lifephase: '\u9032\u884c\u4e2d' },
        { lifephase_id: '5', lifephase: '\u72c0\u6cc1\u56de\u5831' },
        { lifephase_id: '6', lifephase: '\u88dd\u6a5f\u5b8c\u6210' },
        { lifephase_id: '7', lifephase: '\u95dc\u9589' },
        { lifephase_id: '8', lifephase: '\u78ba\u8a8d\u5b8c\u6210' },
      ],
    },
    msg: 'Get Workorder Status List Successfully',
  });

  mock.onGet('/get_workorder_type.php').reply(200, {
    status: 0,
    data: {
      wo_type: [
        {
          type: '裝機',
          type_id: '1',
        },
        {
          type: '參數設定',
          type_id: '2',
        },
        {
          type: '拆機',
          type_id: '3',
        },
        {
          type: '併機',
          type_id: '4',
        },
      ],
    },
    msg: 'success',
  });

  mock.onGet('/get_workorder_report.php').reply(200, {
    status: 0,
    data: {
      report: [
        {
          report_id: '1',
          type: 'error',
          name: 'ABC',
          create_time: 'XXXX-XX-XX XX:XX:XX',
        },
      ],
      current_page: 1,
      last_page: 1,
      limit: 21,
      total: 3,
    },
    msg: 'success',
  });

  mock.onGet('/get_workorder_report_info.php').reply(200, {
    status: 0,
    data: {
      status: '客服處理',
      trouble: '下載KEY',
      content: '',
      create_time: '2022-08-15 18:12:50',
      name: 'janaka',
      photo: [
        'cdn/PHOTOFile/62/photo/u2glsnjpr9d766cw.jpg',
        'cdn/PHOTOFile/62/photo/3rqqcymzhzv47p1u.jpg',
        'cdn/PHOTOFile/62/photo/u67ys0wr4k6784jq.jpg',
      ],
      customer_sign: 'https://uat-dispatch.castlestech.com/cdn/PHOTOFile/126/photo/95upro6f4w37n7eq.jpg',
      engineer_sign: 'https://uat-dispatch.castlestech.com/cdn/PHOTOFile/126/photo/95upro6f4w37n7eq.jpg',
      electronic_receipt:
        'https://uat-dispatch.castlestech.com/cdn/PDF/201/electronic_receipt/1ci7by3snc0m6b0u.pdf',
      electronic_worksheet:
        'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    },
    msg: 'Get Workorder Report Info Successfully',
  });

  mock.onPost('/set_workorder.php').reply(({ data }) => {
    console.log('Post: /set_workorder.php', data);
    return [
      200,
      {
        status: 0,
        data: {},
        msg: 'success',
      },
    ];
  });

  mock.onPost('/set_workorder_batch.php').reply(({ data }) => {
    console.log('Post: /set_workorder_batch.php', data);
    return [
      200,
      {
        status: 0,
        data: {},
        msg: 'success',
      },
    ];
  });

  mock.onPost('/create_workorder_by_workorder.php').reply(200, {
    status: 0,
    data: '334',
    msg: 'Create New Workorder Successfully',
  });

  mock.onGet('/get_log.php').reply(200, {
    status: 0,
    data: {
      current_page: '1',
      last_page: 1,
      limit: '3',
      total: '3',
      logs: [
        {
          log_id: '680',
          action: 'login to CSDS system.',
          status: '0',
          target: '',
          create_time: '2022-08-11 11:18:03',
          first_name: 'First',
          last_name: 'Last',
        },
        {
          log_id: '670',
          action: 'login to CSDS system.',
          status: '0',
          target: '',
          create_time: '2022-08-11 10:31:04',
          first_name: 'First',
          last_name: 'Last',
        },
        {
          log_id: '521',
          action: 'modify the engineer',
          status: '0',
          target: '4',
          create_time: '2022-08-10 11:39:58',
          first_name: 'First',
          last_name: 'Last',
        },
      ],
    },
    msg: 'Get Log List Successfully',
  });

  mock.onGet('/get_report_export.php').reply(200, {
    status: 0,
    data: 'cdn/tmpfile/20220809/WorkorderReport_20220809.xlsx',
    msg: 'Get report Successfully',
  });

  mock.onGet('/get_urgency.php').reply(200, {
    status: 0,
    data: {
      urgency: [
        {
          urgency: '普通件',
          ur_id: '1',
        },
      ],
    },
    msg: 'success',
  });

  mock.onPost('/set_hwsn_for_workorder.php').reply(200, {
    status: 0,
    data: '12345678',
    msg: 'success',
  });

  mock.onGet('/get_no_workorder_report.php').reply(200, {
    status: 0,
    data: {
      report: [
        {
          report_id: '1',
          status: 'error',
          trouble: '其他',
          content: 'ABC',
          create_time: 'XXXX-XX-XX XX:XX:XX',
          name: 'janaka',
          MID: '12345678',
          TID: '77777777',
          message: '',
        },
        {
          report_id: '2',
          status: 'error',
          trouble: '其他',
          content: 'ABC',
          create_time: 'XXXX-XX-XX XX:XX:XX',
          name: 'janaka',
          MID: '12345678',
          TID: '77777777',
          message: '',
        },
      ],
      current_page: 1,
      last_page: 1,
      limit: 21,
      total: 3,
    },
    msg: 'success',
  });

  mock.onPost('/set_report_for_workorder.php').reply(200, {
    status: 0,
    data: {},
    msg: 'success',
  });
};

export default Mock;
