import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import formatDate from '../../utils/format/formatDate';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { error } from '../../module/message';
import { getOwnerList } from '../../module/owner/api';
import { getReportExport } from '../../module/report/api';
import Section from '../../components/section/Section';
import SectionBody from '../../components/section/SectionBody';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';
import { Grid, Column } from '../../components/grid/Grid';
import FormItem from '../../components/form/FormItem';
import Label from '../../components/form/Label';
import Checkbox from '../../components/form/Checkbox';
import Select from '../../components/select/Select';
import DatetimePickerV2 from '../../components/datetimePicker/DatetimePickerV2';

const Report = () => {
  const { t } = useTranslation();
  const [ownerList, setOwnerList] = useState([]);
  const [listParams, setListParams] = useState({
    owner: '',
    type: '',
    detail: 0,
    start_time: formatDate(new Date().setHours(0, 0, 0, 0)),
    end_time: formatDate(new Date()),
  });

  const handleChangeParam = (key, value) => {
    if (key === 'end_time' && value <= listParams.start_time) return;

    const newParams = { ...listParams };
    newParams[key] = value;

    setListParams(newParams);
  };

  const handleExport = () => {
    if (!listParams.owner && listParams.owner !== 0) {
      error({ message: t('error:PleaseSelectABank') });
      return;
    }

    if (!listParams.type && listParams.type !== 0) {
      error({ message: t('error:PleaseSelectAType') });
      return;
    }

    const params = {
      owner: listParams.owner,
      type: listParams.type,
      detail: listParams.detail,
      start_time: listParams.start_time,
      end_time: listParams.end_time,
    };

    handleApiResponse(getReportExport(params), (response) => {
      const { data } = response.data;
      window.location.href = data;
    });
  };

  useEffect(() => {
    handleApiResponse(getOwnerList(), (response) => {
      const { owner } = response.data.data;
      const data = owner.map((owner) => {
        return { id: owner.owner_id, text: owner.owner };
      });

      setOwnerList(data);
    });
  }, []);

  return (
    <>
      <Section backgroundReverse>
        <SectionBody noPadding>
          <Grid columns={2}>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='bank' required>
                  {t('Bank')}
                </Label>
                <Select
                  fullWidth
                  options={ownerList}
                  selected={listParams.owner}
                  onSelect={(owner) => handleChangeParam('owner', owner)}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='type' required>
                  {t('Type')}
                </Label>
                <Select
                  fullWidth
                  options={[
                    {
                      id: 1,
                      text: t('WorkOrderReport'),
                    },
                    {
                      id: 2,
                      text: t('TroubleshootingReport'),
                    },
                    {
                      id: 3,
                      text: t('CompleteReplyFile'),
                    },
                  ]}
                  selected={listParams.type}
                  onSelect={(type) => handleChangeParam('type', type)}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='startTime' required>
                  {t('StartTime')}
                </Label>
                <DatetimePickerV2
                  selected={listParams.start_time}
                  onConfirm={(date) => {
                    handleChangeParam('start_time', date);
                  }}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='endTime' required>
                  {t('EndTime')}
                </Label>
                <DatetimePickerV2
                  selected={listParams.end_time}
                  onConfirm={(date) => {
                    handleChangeParam('end_time', date);
                  }}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Checkbox
                  htmlFor='includePhotoAndSignature'
                  text={t('IncludePhotoAndSignature')}
                  onChange={() => {
                    handleChangeParam('detail', listParams.detail ? 0 : 1);
                  }}
                  checked={listParams.detail}
                />
              </FormItem>
            </Column>
          </Grid>
          <ButtonGroup alignCenter>
            <Button primary onClick={handleExport}>
              {t('button:Export')}
            </Button>
          </ButtonGroup>
        </SectionBody>
      </Section>
    </>
  );
};

export default Report;
