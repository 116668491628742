import { callApi } from '../../services/api';

const getWorkOrderList = (data) => {
  const params = {
    page: data.page,
    limit: data.limit,
    sort_column: data.sort_column,
    sort_type: data.sort_type,
    search: data.search,
    type: data.type,
    merchant: data.merchant,
    ur_id: data.ur_id,
    principal_type: data.principal_type,
    principal: data.principal,
    start_time: data.start_time,
    end_time: data.end_time,
    lifephase_id: data.lifephase_id,
  };

  return callApi.get('/get_workorder.php', {
    params: params,
  });
};

const getWorkOrderListByDemandOrder = (data) => {
  const params = {
    cs_id: data.cs_id,
  };

  return callApi.get('/get_workorder_by_customerservice.php', {
    params: params,
  });
};

const getWorkOrderHistory = (data) => {
  const params = {
    wo_id: data.wo_id,
  };

  return callApi.get('/get_workorder_log.php', {
    params: params,
  });
};

const getWorkOrderInformation = (data) => {
  const params = {
    wo_id: data.wo_id,
  };

  return callApi.get('/get_workorder_info.php', {
    params: params,
  });
};

const getWorkOrderStatusList = () => {
  return callApi.get('/get_workorder_status.php');
};

const getWorkOrderTypeList = () => {
  return callApi.get('/get_workorder_type.php');
};

const getWorkOrderReportList = (data) => {
  const params = {
    wo_id: data.wo_id,
    search: data.search,
    sort_column: data.sort_column,
    sort_type: data.sort_type,
    page: data.page,
    limit: data.limit,
  };

  return callApi.get('/get_workorder_report.php', {
    params: params,
  });
};

const getWorkOrderReportInformation = (data) => {
  const params = {
    report_id: data.report_id,
  };

  return callApi.get('/get_workorder_report_info.php', {
    params: params,
  });
};

const createWorkOrderReport = (data) => {
  const params = {
    wo_id: data.wo_id,
    status: data.status,
    memo: data.memo,
  };

  return callApi.post('/create_workorder_report.php', params);
};

/**
 * preview work order
 *
 * @return {Promise}
 */
const previewWorkOrder = (data) => {
  return callApi.post('/preview_workorder.php', data);
};

/**
 * import work order
 *
 * @return {Promise}
 */
const importWorkOrder = (data) => {
  return callApi.post('/import_workorder.php', data);
};

const modifyWorkOrder = (data) => {
  const params = {
    wo_id: data.wo_id,
    real_time: data.real_time,
    principal_type: data.principal_type,
    principal: data.principal,
    wo_status: data.wo_status,
    memo: data.memo,
    actual_address: data.actual_address,
  };

  return callApi.post('/set_workorder.php', params);
};

const batchModifyWorkOrder = (data) => {
  const params = {
    wo_id: data.wo_id,
    principal_type: data.principal_type,
    principal: data.principal,
    wo_status: data.wo_status,
    memo: data.memo,
  };

  return callApi.post('/set_workorder_batch.php', params);
};

const createWorkOrderBasedOnWorkOrder = (data) => {
  const params = {
    number: data.number,
    wo_id: data.wo_id,
  };

  return callApi.post('/create_workorder_by_workorder.php', params);
};

const generateWorkOrder = (data) => {
  const params = {
    number: data.number,
    MID: data.MID,
    TID: data.TID,
    request_class: data.request_class,
    time: data.time,
    name_ch: data.name_ch,
    name_en: data.name_en,
    contact: data.contact,
    address: data.address,
    phone: data.phone,
    header_1: data.header_1,
    s_contact: data.s_contact,
    s_address: data.s_address,
    s_phone: data.s_phone,
  };

  return callApi.post('/create_workorder_file.php', params);
};

const getUrgencyList = () => {
  return callApi.get('/get_urgency.php');
};

const syncHardwareSerialNumber = (data) => {
  return callApi.post('/set_hwsn_for_workorder.php', {
    wo_id: data.wo_id,
  });
};

const getPendingReportList = (data) => {
  const params = {
    page: data.page,
    limit: data.limit,
    sort_column: data.sort_column,
    sort_type: data.sort_type,
    search: data.search,
    owner_id: data.owner_id,
  };

  return callApi.get('/get_no_workorder_report.php', {
    params: params,
  });
};

const linkReportToWorkOrder = (data) => {
  const params = {
    wo_id: data.wo_id,
    report_id: data.report_id,
  };

  return callApi.post('/set_report_for_workorder.php', params);
};

export {
  getWorkOrderList,
  getWorkOrderListByDemandOrder,
  getWorkOrderHistory,
  getWorkOrderInformation,
  getWorkOrderStatusList,
  getWorkOrderTypeList,
  getWorkOrderReportList,
  getWorkOrderReportInformation,
  createWorkOrderReport,
  previewWorkOrder,
  importWorkOrder,
  modifyWorkOrder,
  batchModifyWorkOrder,
  createWorkOrderBasedOnWorkOrder,
  generateWorkOrder,
  getUrgencyList,
  syncHardwareSerialNumber,
  getPendingReportList,
  linkReportToWorkOrder,
};
