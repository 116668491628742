import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FaFilter, FaGenderless } from 'react-icons/fa';
import handleApiResponse from '../../../utils/api/handleApiResponse';
import formatDate from '../../../utils/format/formatDate';
import {
  getWorkOrderInformation,
  getWorkOrderHistory,
  syncHardwareSerialNumber,
} from '../../../module/workOrder/api';
import { error } from '../../../module/message';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import Section from '../../../components/section/Section';
import SectionHeader from '../../../components/section/SectionHeader';
import SectionBody from '../../../components/section/SectionBody';
import SectionToolbar from '../../../components/section/SectionToolbar';
import Heading1 from '../../../components/heading/Heading1';
import Tab from '../../../components/Tab';
import Button from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import TitleModal from '../../../components/modal/TitleModal';
import Timeline from '../../../components/timeline/Timeline';
import BasicInformation from './BasicInformation';
import CustomerInformation from './CustomerInformation';
import InstallationInformation from './InstallationInformation';
import Parameter from './Parameter';
import Report from './Report';

const Detail = ({ pathData }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState('basicInformation');
  const [data, setData] = useState({
    wo_number: '',
    type: '',
    real_time: formatDate(new Date()),
    urgency: '',
    wo_status: '',
    principal: '',
    memo: '',
  });
  const [previousData, setPreviousData] = useState({});
  const [workOrderInformation, setWorkOrderInformation] = useState({
    lifephase_id: '',
  });
  const [customerInformation, setCustomerInformation] = useState({
    merchant_id: '',
    name: '',
    contact_person: '',
    phone: '',
    address: '',
  });
  const [installationInformation, setInstallationInformation] = useState({
    hwsn: '',
    terminal_id: '',
    model: '',
    header: '',
    contact_person: '',
    phone: '',
    address: '',
    actual_address: '',
    memo: '',
  });
  const [previousInstallationInformation, setPreviousInstallationInformation] =
    useState({});
  const [parameter, setParameter] = useState([]);

  const handleGetWorkOrderInformation = useCallback(() => {
    handleApiResponse(getWorkOrderInformation({ wo_id: id }), (response) => {
      const { data } = response.data;
      const basicInformation = {
        wo_number: data.workorder.workorder[0]['Number'],
        type: data.workorder.workorder[0]['type'],
        real_time: data.real_time,
        urgency: data.urgency,
        wo_status: parseInt(data.lifephase_id, 10),
        principal: `${data.principal_type}_${data.principal}`,
        memo: data.Memo,
      };
      setData(basicInformation);
      setPreviousData(basicInformation);
      setWorkOrderInformation({
        lifephase_id: data.lifephase_id,
      });
      setCustomerInformation({
        merchant_id: data.workorder.workorder[0]['MerchantID'],
        name: data.merchant,
        contact_person: data.workorder.workorder[0]['Contact Person'],
        phone: data.workorder.workorder[0]['Phone'],
        address: data.workorder.workorder[0]['Address'],
      });
      const installationInformation = {
        hwsn: data.hwsn,
        terminal_id: data.workorder.terminal[0]['TerminalID'],
        model: data.workorder.terminal[0]['Model'],
        header: data.workorder.workorder[0]['Header 1'],
        contact_person: data.workorder.workorder[0]['Contact Person'],
        phone: data.workorder.workorder[0]['Phone'],
        address: data.workorder.workorder[0]['Address'],
        actual_address: data.actual_address,
        memo: data.workorder.workorder[0]['Memo'],
      };
      setInstallationInformation(installationInformation);
      setPreviousInstallationInformation(installationInformation);

      const parameter = [{ label: 'General', data: {} }];
      Object.entries(data.workorder.parameter).forEach(([key, value]) => {
        if (typeof value === 'object') {
          parameter.push({ label: key, data: value });
        } else {
          parameter[0].data[key] = value;
        }
      });
      setParameter(parameter);
    });
  }, [id]);

  const handleSyncHardwareSerialNumber = () => {
    return new Promise((resolve) => {
      handleApiResponse(
        syncHardwareSerialNumber({ wo_id: id }),
        (response) => {
          const { data } = response.data;
          resolve(data);
        },
        () => {
          resolve(null);
        }
      );
    });
  };

  const showHistory = useCallback(() => {
    handleApiResponse(getWorkOrderHistory({ wo_id: id }), (response) => {
      const { data } = response.data;

      if (data) {
        TitleModal({
          size: 'normal',
          children: <HistoryModal data={data} />,
        });
      } else {
        error({ message: t('error:NoHistoryYet') });
      }
    });
  }, [t, id]);

  useEffect(() => {
    handleGetWorkOrderInformation();
  }, [handleGetWorkOrderInformation]);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader inline>
          <Heading1>{t('WorkOrderDetails')}</Heading1>
          <SectionToolbar>
            <Button secondary onClick={showHistory}>
              <FaFilter />
              {t('History')}
            </Button>
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Tab selected={selectedTab} onChange={setSelectedTab}>
            <Tab.List>
              <Tab.Tab name='basicInformation'>{t('BasicInformation')}</Tab.Tab>
              <Tab.Tab name='customerInformation'>
                {t('CustomerInformation')}
              </Tab.Tab>
              <Tab.Tab name='installationInformation'>
                {t('InstallationInformation')}
              </Tab.Tab>
              <Tab.Tab name='parameter'>{t('Parameter')}</Tab.Tab>
              <Tab.Tab name='report'>{t('Report')}</Tab.Tab>
            </Tab.List>

            <Tab.Panel name='basicInformation'>
              <BasicInformation
                previousData={previousData}
                setPreviousData={setPreviousData}
                data={data}
                setData={setData}
                installationInformation={previousInstallationInformation}
                handleGetWorkOrderInformation={handleGetWorkOrderInformation}
                handleSyncHardwareSerialNumber={handleSyncHardwareSerialNumber}
              />
            </Tab.Panel>

            <Tab.Panel name='customerInformation'>
              <CustomerInformation data={customerInformation} />
            </Tab.Panel>

            <Tab.Panel name='installationInformation'>
              <InstallationInformation
                previousData={previousInstallationInformation}
                setPreviousData={setPreviousInstallationInformation}
                data={installationInformation}
                setData={setInstallationInformation}
                basicInformation={data}
              />
            </Tab.Panel>

            <Tab.Panel name='parameter'>
              <Parameter data={parameter} />
            </Tab.Panel>

            <Tab.Panel name='report'>
              <Report lifephaseId={workOrderInformation.lifephase_id} />
            </Tab.Panel>
          </Tab>
        </SectionBody>
      </Section>
    </>
  );
};

const HistoryModal = ({ data, close }) => {
  const { t } = useTranslation();

  return (
    <>
      <TitleModal.Header handleClose={close}>{t('History')}</TitleModal.Header>
      <TitleModal.Body>
        <Timeline>
          <Timeline.Items>
            {data.map((history) => {
              return (
                <Timeline.Item key={history.wol_id}>
                  <Timeline.ItemCircle success>
                    <FaGenderless />
                  </Timeline.ItemCircle>
                  <Timeline.ItemDescription>
                    <Timeline.ItemTitle>
                      {history.username}(
                      {history.wol_principal_type === 1
                        ? t('CustomerService')
                        : t('Engineer')}
                      )&nbsp;{history.time}
                    </Timeline.ItemTitle>
                    <Timeline.ItemText>
                      {t('Assignee')}: {history.principalname}
                    </Timeline.ItemText>
                    <Timeline.ItemText>
                      {t('ExpectFinishDate')}: {history.real_time}
                    </Timeline.ItemText>
                    <Timeline.ItemText>
                      {t('Status')}: {history.lifephase}
                    </Timeline.ItemText>
                    <Timeline.ItemText>
                      {t('Memo')}: {history.memo}
                    </Timeline.ItemText>
                  </Timeline.ItemDescription>
                </Timeline.Item>
              );
            })}
          </Timeline.Items>
        </Timeline>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Close')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

export default Detail;
