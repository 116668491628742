import { createContext, useState, useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';

const AuthenticateContext = createContext();

const AuthenticateContextProvider = ({ children }) => {
  const [isGetting, setIsGetting] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [userInformation, setUserInformation] = useState(null);

  const updateAuthenticate = useCallback((userInfo) => {
    setAuthenticated(true);
    setUserInformation(userInfo);
    Cookies.set('DSUSERINFO', JSON.stringify(userInfo));
  }, []);

  const removeAuthenticate = useCallback(() => {
    setAuthenticated(false);
    setUserInformation(null);
    Cookies.remove('PHPSESSID');
    Cookies.remove('DSUSERINFO');
  }, []);

  const initialAuthenticate = useCallback(() => {
    if (Cookies.get('PHPSESSID') && Cookies.get('DSUSERINFO')) {
      const userInfo = JSON.parse(Cookies.get('DSUSERINFO'));
      updateAuthenticate(userInfo);
    }

    setIsGetting(false);
  }, [updateAuthenticate]);

  useEffect(() => {
    initialAuthenticate();
  }, [initialAuthenticate]);

  return (
    <AuthenticateContext.Provider
      value={{
        isGetting,
        isAuthenticated,
        userInformation,
        updateAuthenticate,
        removeAuthenticate,
      }}
    >
      {children}
    </AuthenticateContext.Provider>
  );
};

export default AuthenticateContext;
export { AuthenticateContextProvider };
